.link_grid{
    margin-right: 5px;
    cursor: pointer;
}

.container_pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.box_verify {
	inline-size: 1px;
    overflow: hidden;
}