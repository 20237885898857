.persona-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 250px;
}

.persona-area svg {
    color: #2D4C71;
    margin-right: 15px;
}

.persona-customer-name {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px; 
    color: #2D4C71;
}

.persona-customer-document {
    display: flex;
    margin-bottom: 20px;
}

.persona-customer-document p {
    font-weight: 600;
    color: #2D4C71;
    font-size: 14px;
    word-break: break-word;
}