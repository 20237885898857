.fa-business-time { 
	padding-right: 5px; 
}

.fa-spinner {
	padding-right: 5px;
	color: #025cfb
}

.fa-handshake {
	padding-right: 5px;
	color: #ff7433;
}

.fa-check-circle {
	padding-right: 5px; 
	color: #268f30;
}

.fa-ban {
	padding-right: 5px;
	color: #ca0e0e;
}

.fa-stopwatch {
	padding-right: 5px;
	color: #03dbf8;
}