.customer-view-container {
    display: flex;
}

.customer-view-info-item {
    margin: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px #ddd;
}

.customer-view-table {
    width: 100%;
}

.customer-view-table-items {
    margin: 10px;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    border: 1px solid #ddd;
    border-radius: 5px;
    position: relative;
}

.customer-view-persona-container {
    background-color: #F1FCFF;    
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

@media(max-width: 1250px) {
    .customer-view-container {
        flex-direction: column;
    }
}