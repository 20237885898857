.loader {
    display: none;
    border: 16px solid #ccc;
    border-radius: 50%;
    border-top: 15px solid #118287;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    position:fixed;
}

a, .cursorPointer{
    cursor: pointer;
}

.notification-default{
    background-color: #0A5558;
    color: #fff;
    font-weight: bold;
}