input, select, textarea, button{
    margin-bottom: 10px;
}

.required:after{
    content: "*";
    color: #ff0000;
}

.form-input{
    color: '#344675';
}

input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.link_grid{
    margin-right: 5px;
    cursor: pointer;
}

.container_pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.drop-container {
  position: relative;
  display: inline-flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 30px;
  border-radius: 10px;
  border: 2px dashed #55555554;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}



